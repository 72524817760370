import React from "react";
import { IconButton, Link, TableBody, TableCell, TableRow } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import CardTitleBar from 'component/common/CardTitleBar';
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import TableNoBb from "component/styled/TableNoBb";
import { useTranslation } from "react-i18next";
import PaymentPlan from "model/paymentPlan";
import { route, routes } from "helper/route";

type Props = {
  paymentPlan: PaymentPlan;
  onEdit: Function;
  isReloading: boolean;
};

const SectionInfoView = ({ paymentPlan, onEdit: onCancel, isReloading }: Props) => {

  const { isGranted } = useAccess();
  const { t } = useTranslation();

  return <React.Fragment>
    <CardTitleBar title={t("generalInfo")} {...(paymentPlan.isPublic ? { chip: 'Public' } : { chip: 'Private', bgcolor: 'warning.light' })}>
      {isGranted(perms.edit_payment_plans) && <IconButton onClick={() => onCancel(1)}><Edit /></IconButton>}
    </CardTitleBar>
    <TableNoBb>
      <TableBody>
        <TableRow>
          <TableCell>{t("name")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("price")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.price}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("tagLimit")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.tagLimit}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("userLimit")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.userLimit}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("clientLimit")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.clientLimit}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("foreignTagFee")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.foreignTagFee}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("currency")}</TableCell>
          <TableCell className="preview-value"><Link href={route(routes.view_currency, paymentPlan.currencyId)}>{paymentPlan.currencyName}</Link></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("stripePriceId")}</TableCell>
          <TableCell className="preview-value">{paymentPlan.stripePriceId}</TableCell>
        </TableRow>
      </TableBody>
    </TableNoBb>
    {isReloading && <Preloader container="parent" />}
  </React.Fragment>
}

export default SectionInfoView;
import React from 'react';
import TopBar from 'component/TopBar';

import Box from '@mui/material/Box';
import SideBar from 'component/common/SideBar';
import { Typography } from '@mui/material';
import env from 'env';
import { useTranslation } from 'react-i18next';
import { useOnboarding } from 'context/onboarding';

const headerPlusFooterHeight = 100;

type Props = {
  children: any;
};

const PrivateLayout = ({ children }: Props) => {

  const { t } = useTranslation();
  const { client } = useOnboarding();

  return <Box sx={{ display: 'flex' }}>
    <SideBar sx={{ display: { xs: 'none', lg: 'block' } }} />
    {/* This div has 50% width because of the data tables. This width does not influnce the effective width because the element has flexGrow on it, the width must be present so the datagrid will resize properly */}
    <Box sx={{ flexGrow: 1, backgroundColor: '#f5f6f8', width: "50%" }}>
      {!!client?.isSuspended && <Box sx={{ height: "35px" }} className="suspended-bar">{t("duAccessSuspended")}</Box>}
      <TopBar />
      <Box sx={{ p: 3, minHeight: `calc(100vh - ${headerPlusFooterHeight}px ${!!client?.isSuspended ? '- 27px' : ""})` }}>
        {children}
      </Box>
      <Box sx={{ textAlign: 'center', pt: 1, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="caption">
          © {new Date().getFullYear()} {env.APP_TITLE} by <a href="https://www.codeadept.ro" target="_blank" rel="noreferrer" style={{ color: "#33AD93" }}>CodeAdept</a>
        </Typography>
        <Typography variant="caption" sx={{ mx: 1 }}>|</Typography>
        <Typography variant="caption">Version {env.APP_VERSION}</Typography>
      </Box>
    </Box>
  </Box >
}

export default PrivateLayout;

import React, { ChangeEvent } from 'react';
import { Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DateRangeInput from 'component/common/DateRangeInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, setFieldValue }: Props) => {
  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <DateRangeInput value={values.activatedTs} errors={errors} setStatus={setStatus} setFieldValue={(value) => setFieldValue('activatedTs', value)} disableClear />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;
import {
  APPLY_FOREIGN_TAG_DT_PARAMS,
  PATCH_FOREIGN_TAG_DT_PARAMS,
} from './actionTypes';
import { DtParams } from '@type';

/********** DT PARAMS **********/

export const applyForeignTagDtParams = (params: DtParams) => ({
  type: APPLY_FOREIGN_TAG_DT_PARAMS,
  payload: { params },
});

export const patchForeignTagDtParams = (params: DtParams) => ({
  type: PATCH_FOREIGN_TAG_DT_PARAMS,
  payload: { params },
});
import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { getOwnOrganization } from "helper/backend";
import { toSimpleError } from "helper/util";
import { AppError } from "@type";
import Organization from "model/organization";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import SectionBilling from "./Partial/SectionBilling";
import SectionInfo from "./Partial/SectionInfo";
import SectionSettings from "./Partial/SectionSettings";
import { useTranslation } from "react-i18next";
import SectionStripe from "./Partial/SectionStripe";
import { useAuth } from "context/auth";
import SectionClientSelfInvite from "./Partial/SectionClientSelfInvite";

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();
  const { authUser } = useAuth();

  // the organization record fetched from the backend
  const [organization, setOrganization] = useState<Organization | undefined>()
  // error encoutered while fetching the organization (if any)
  const [organizationError, setOrganizationerror] = useState<AppError | undefined>();
  // whether the loading of the organization is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the organization record from the backend
  */
  const refreshOrganization = useCallback(() => {
    setIsLoadInProgress(true)
    getOwnOrganization()
      .then(response => {
        setOrganization(response.organization);
      })
      .catch(ex => {
        setOrganizationerror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [])

  // This hook runs once on component mount
  useEffect(() => {
    refreshOrganization();
  }, [refreshOrganization]);

  return (
    <>
      {isGranted(perms.view_own_organization)
        &&
        <>
          {
            !!organization
            &&
            <Box>
              <PageTitleBar title={organization.companyName!} />
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionBilling organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                </Grid>
                {
                  authUser.isOrganizationAdmin() &&
                  <>
                    <Grid xs={12} md={6}>
                      <SectionSettings organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <SectionStripe organization={organization} refreshHandler={refreshOrganization} isLoadInProgress={isLoadInProgress} />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <SectionClientSelfInvite organization={organization} />
                    </Grid>
                  </>
                }
              </GridContainer>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !organization && <Preloader container="content" />}
          {!!organizationError && <Error error={organizationError} title404={t("organizationNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_own_organization) && <AccessDenied />}
    </>
  )
}

export default Single;
import { Billing } from "@type/backend";
import Currency from "./currency";

type InvoiceLines = {
  id: number,
  description: string,
  createdTs: number,
  invoiceId: number,
  quantity: number,
  vatValue: string,
  totalAmount: number,
  unitAmount: number,
}

type BillingInfo = {
  issuer: Billing,
  recipient: Billing,
  currency: Currency
}

export default class Invoice {
  id!: number;
  number!: number;
  lines!: InvoiceLines[];
  status!: number;
  organizationId!: number;
  clientId!: number;
  totalAmount!: number;
  billingInfo!: BillingInfo;
  txRate!: string;
  createdTs!: number;
  updatedTs!: number;

  // INVOICE STATUS
  static STATUS_OPEN = 0;
  static STATUS_PAID = 1;

  // INVOICE TYPE
  static TYPE_SERVICE = 1;
  static TYPE_PENALTY = 2;
  static TYPE_MAX_PENALTY = 3;
  static TYPE_SUBSCRIPTION = 4;
  static TYPE_FOREIGN_TAGS = 5;

  static StatusTypes = [
    {
      id: this.STATUS_OPEN,
      name: "Open"
    },
    {
      id: this.STATUS_PAID,
      name: "Paid"
    }
  ];

  static InvoiceType = [
    {
      id: this.TYPE_SERVICE,
      name: "Service"
    },
    {
      id: this.TYPE_PENALTY,
      name: "Penalty"
    },
    {
      id: this.TYPE_MAX_PENALTY,
      name: "Max Penalty"
    },
    {
      id: this.TYPE_SUBSCRIPTION,
      name: "Subscription"
    },
    {
      id: this.TYPE_FOREIGN_TAGS,
      name: "Foreign Tags"
    }
  ]

  static getStatusTypeName(id: number) {
    return this.StatusTypes.find((statusType) => statusType.id === id)?.name
  }

  static getInvoiceTypeName(id: number) {
    return this.InvoiceType.find((type) => type.id === id)?.name
  }
}
import React from "react";
import { Box, Button, CardContent, Link, Tooltip } from "@mui/material";
import { useAuth } from "context/auth";
import EditableCard from "component/styled/EditableCard";
import { routes } from "helper/route";
import { AccountBalanceWallet, AccountCircle, BarChart, Business, DryCleaning, LocalShipping, People, Security, Settings, TableRows } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AccessRole from "model/accessRole";

const Account = () => {

  const { authUser } = useAuth();
  const { t } = useTranslation();

  return (
    <EditableCard sx={{ height: { xs: "260px", md: "360px" }, overflowY: "auto", padding: 0 }}>
      <CardContent sx={{ padding: "0px !important" }}>
        <Box sx={{ background: "linear-gradient(90deg, rgba(47,153,131,1) 0%, rgba(51,173,147,1) 48%, rgba(144,221,205,0.773546918767507) 100%)", width: "100%", height: { xs: "260px", md: "360px" }, p: 3, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box>
            <Box sx={{ fontSize: "30px", fontWeight: 500 }}>
              Welcome back to 24Linen,
            </Box>
            <Box sx={{ fontSize: "26px", fontWeight: 500 }}>
              {authUser.fullName}!
            </Box>
            <Box sx={{ fontSize: "16px", fontWeight: 400, color: "#0000007a" }}>
              {authUser.accessRoleName}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ backgroundColor: "#87d9c7", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
              {
                authUser.isMasterAdmin() &&
                <>
                  <Tooltip title={t("myAccount")}>
                    <Button component={Link} href={routes.my_account} sx={{ padding: "6px 0px", minWidth: "50px" }}><AccountCircle sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("myBillingInfo")}>
                    <Button component={Link} href={routes.my_billing} sx={{ padding: "6px 0px", minWidth: "50px" }}><AccountBalanceWallet sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("organizations")}>
                    <Button component={Link} href={routes.list_organizations} sx={{ padding: "6px 0px", minWidth: "50px" }}><Business sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("settings")}>
                    <Button component={Link} href={routes.master_settings} sx={{ padding: "6px 0px", minWidth: "50px" }}><Settings sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                </>
              }
              {
                authUser.isOrganizationAdmin() &&
                <>
                  <Tooltip title={t("myAccount")}>
                    <Button component={Link} href={routes.my_account} sx={{ padding: "6px 0px", minWidth: "50px" }}><AccountCircle sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("myOrganization")}>
                    <Button component={Link} href={routes.my_organization} sx={{ padding: "6px 0px", minWidth: "50px" }}><Business sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("articleOverview")}>
                    <Button component={Link} href={routes.list_article_overview} sx={{ padding: "6px 0px", minWidth: "50px" }}><BarChart sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("securityOverview")}>
                    <Button component={Link} href={routes.list_security_overview} sx={{ padding: "6px 0px", minWidth: "50px" }}><Security sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                </>
              }
              {
                authUser.isClientAdmin() &&
                <>
                  <Tooltip title={t("myAccount")}>
                    <Button component={Link} href={routes.my_account} sx={{ padding: "6px 0px", minWidth: "50px" }}><AccountCircle sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("myClient")}>
                    <Button component={Link} href={routes.my_client} sx={{ padding: "6px 0px", minWidth: "50px" }}><People sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("inHouseArticles")}>
                    <Button component={Link} href={routes.list_in_house_articles} sx={{ padding: "6px 0px", minWidth: "50px" }}><TableRows sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  {
                    authUser.isNonSsdClient() ?
                      <Tooltip title={t("dispatches")}>
                        <Button component={Link} href={routes.list_expeditions} sx={{ padding: "6px 0px", minWidth: "50px" }}><LocalShipping sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                      </Tooltip>
                      :
                      <Tooltip title={t("pickups")}>
                        <Button component={Link} href={routes.list_pickups} sx={{ padding: "6px 0px", minWidth: "50px" }}><DryCleaning sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                      </Tooltip>
                  }
                </>
              }
              {
                authUser.accessRoleId === AccessRole.ID_ORGANIZATION_DRIVER &&
                <>
                  <Tooltip title={t("myAccount")}>
                    <Button component={Link} href={routes.my_account} sx={{ padding: "6px 0px", minWidth: "50px" }}><AccountCircle sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("myOrganization")}>
                    <Button component={Link} href={routes.my_organization} sx={{ padding: "6px 0px", minWidth: "50px" }}><Business sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("dispatches")}>
                    <Button component={Link} href={routes.list_expeditions} sx={{ padding: "6px 0px", minWidth: "50px" }}><LocalShipping sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                </>
              }
              {
                authUser.accessRoleId === AccessRole.ID_ORGANIZATION_SECURITY &&
                <>
                  <Tooltip title={t("myAccount")}>
                    <Button component={Link} href={routes.my_account} sx={{ padding: "6px 0px", minWidth: "50px" }}><AccountCircle sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                  <Tooltip title={t("myOrganization")}>
                    <Button component={Link} href={routes.my_organization} sx={{ padding: "6px 0px", minWidth: "50px" }}><Business sx={{ color: "#111b19", fontSize: "30px" }} /></Button>
                  </Tooltip>
                </>
              }
            </Box>
          </Box>
        </Box>
      </CardContent>
    </EditableCard >
  )
};

export default Account;
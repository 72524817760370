import React, { useState, FocusEvent } from "react";
import { Button, TextField } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import ProgressButton from "component/common/ProgressButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateOwnBilling } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import { Billing } from "@type/backend";

type Values = {
  invoicePrefix: string;
};

type Props = {
  billingInfo: Billing;
  onFinish: Function;
};

const SectionSettingsEdit = ({ billingInfo, onFinish }: Props) => {

  const { t } = useTranslation();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    invoicePrefix: ''
  };
  formInitialValues = {
    ...formInitialValues,
    invoicePrefix: billingInfo.invoicePrefix,
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    invoicePrefix: Yup.string()
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveBilling(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveBilling = (values: any) => {
    setIsSubmitInProgress(true)
    updateOwnBilling(values)
      .then(_response => {
        showSuccess(t("billingInfoHasBeenSaved"));
        onFinish(true);
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        showError(t("unableToSaveBilling"));
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return <form noValidate onSubmit={handleSubmit}>
    <CardTitleBar title={t("settings")} sx={{ mb: 4 }} >
      <ProgressButton type="submit" name="submitGeneralInfo" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveSettings")}</ProgressButton>
      <Button variant="contained" color="secondary" onClick={() => onFinish()} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
    </CardTitleBar>
    <GridContainer spacing={2}>
      <Grid xs={12} md={6}>
        <TextField name="invoicePrefix" label={t("invoicePrefix")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.invoicePrefix} error={!!errors.invoicePrefix} helperText={errors.invoicePrefix} sx={{ width: '100%' }} />
      </Grid>
    </GridContainer>
  </form>
}

export default SectionSettingsEdit;

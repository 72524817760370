import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent, Link, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { getUpcomingHandovers } from "helper/backend";
import { useTranslation } from "react-i18next";
import { buildOperationNumber, showDaysText, showError, toSimpleError } from "helper/util";
import TableNoBb from "component/styled/TableNoBb";
import { OPERATION_NOT_FOUND } from "helper/error";
import { Pickups } from "@type/backend";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { Warning } from "@mui/icons-material";

const UpcomingHandovers = () => {
  const { t } = useTranslation();

  // Holds the upcoming handovers received from backend
  const [upcomingHandovers, setUpcomingHandovers] = useState<Pickups[]>([])
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchUpcomingHandovers = useCallback(() => {
    setIsLoadInProgress(true)
    getUpcomingHandovers()
      .then(response => {
        setUpcomingHandovers(response.pickups);
      })
      .catch(ex => {
        const err = toSimpleError(ex)
        if (err.code !== OPERATION_NOT_FOUND) {
          showError(t("unableToLoadUpcomingHandover"));
        }
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchUpcomingHandovers();
  }, [fetchUpcomingHandovers])

  return (
    <EditableCard sx={{ height: "360px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={t("upcomingHandovers")} />
        {
          upcomingHandovers.length > 0
            ?
            <TableNoBb>
              <TableHead>
                <TableRow>
                  <TableCell>{t("dueDate")}</TableCell>
                  <TableCell>{t("remainingTime")}</TableCell>
                  <TableCell>{t("pickupNumber")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  upcomingHandovers.map((el, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          {formatTimestamp(el.handoverBy, formats.DATETIME)}
                        </TableCell>
                        <TableCell>
                          {
                            el.handoverByDays > 0
                              ?
                              <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>
                                {el.handoverByDays >= 5
                                  ?
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                    {el.handoverByDays} {t(showDaysText(el.handoverByDays))}
                                  </Box>
                                  :
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                    {el.handoverByDays} {t(showDaysText(el.handoverByDays))}
                                    <Warning color="warning" />
                                  </Box>
                                }
                              </Typography>
                              :
                              <Typography sx={{ display: "inline", fontSize: "0.960rem" }} >
                                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                  {t("today")}
                                  <Warning color="warning" />
                                </Box>
                              </Typography>
                          }
                        </TableCell>
                        <TableCell><Link href={route(routes.view_pickup_operation, el.operationId)}>{buildOperationNumber(el.operationId, el.type, el.pickupDateTs)}</Link></TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </TableNoBb>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noUpcomingHandoversFound")}
            </Box>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard>
  )
};

export default UpcomingHandovers;
import React, { FocusEvent, useCallback, useEffect, useState } from "react";
import { Card, CardContent, FormControlLabel, Switch, TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CardTitleBar from "component/common/CardTitleBar";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import { getCurrenciesList } from "helper/backend";
import Currency from "model/currency";
import MuiAutocomplete from "component/common/MuiAutocomplete";

const FormCreate = ({ formik }: any) => {

  const { t } = useTranslation();

  const { values, errors, setStatus, setFieldValue, handleChange, setFieldError } = formik;

  // list of currencies that populate the select field
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  /**
  * Fetches from the backend the list of currencies this user is allowed to see
  */
  const fetchCurrencies = useCallback(() => {
    getCurrenciesList()
      .then(response => {
        setCurrencies(response.currencies);
      })
      .catch(_ex => {
        setFieldError('currencyId', t("unableFetchCurrencies"));
      });
  }, [setFieldError, t]);

  /**
  * Converts the list of currencies to select options
  */
  const getCurrencyOptions = () => currencies.map(currency => ({ label: `${currency.name} (${currency.symbol})`, value: currency.id }));

  /**
   * Returns the selected currencies as a select option
   */
  const getSelectedCurrencyOption = () => getCurrencyOptions().find(option => option.value === values.currencyId) || null;

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies])

  return <GridContainer>
    <Grid xs={12} md={6} >
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <CardTitleBar title={t("generalInfo")} />
          <GridContainer spacing={2}>
            <Grid xs={12} md={6}>
              <TextField name="name" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.name} error={!!errors.name} helperText={errors.name} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="price" type="number" label={t("price")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.price} error={!!errors.price} helperText={errors.price} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="tagLimit" type="number" label={t("tagLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.tagLimit} error={!!errors.tagLimit} helperText={errors.tagLimit} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField name="userLimit" type="number" label={t("userLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.userLimit} error={!!errors.userLimit} helperText={errors.contactLuserLimitastName} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField type="number" name="clientLimit" label={t("clientLimit")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.clientLimit} error={!!errors.clientLimit} helperText={errors.clientLimit} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField type="number" name="foreignTagFee" label={t("foreignTagFee")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.foreignTagFee} error={!!errors.foreignTagFee} helperText={errors.foreignTagFee} sx={{ width: '100%' }} />
            </Grid>
            <Grid xs={12} >
              <MuiAutocomplete
                onChange={(_e, selectedOption) => {
                  setFieldValue('currencyId', selectedOption?.value);
                }}
                onFocus={_e => {
                  onSelectFieldFocused('currencyId');
                }}
                value={getSelectedCurrencyOption()}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={getCurrencyOptions()}
                renderInput={(params: any) => <TextField {...params} name="currencyId" label={t("currency")} error={!!errors.currencyId} helperText={errors.currencyId} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
              />
            </Grid>
            <Grid xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!values.isPublic}
                    onChange={(e) => {
                      setFieldValue('isPublic', e.target.checked ? 1 : 0)
                    }}
                    name="isPublic"
                    value={values.isPublic}
                  />
                }
                label={t("public")}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
    </Grid>
  </GridContainer>
}

export default FormCreate;
import { routes } from 'helper/route';
import Home from 'page/Home/index';
import UserList from 'page/User/List';
import UserSingle from 'page/User/Single';
import UserNew from 'page/User/New/index';
import UserNewOrganizationUser from 'page/User/New/OrgUser';
import OrganizationList from 'page/Organization/List';
import OrganizationSingle from 'page/Organization/Single';
import OrganizationNew from 'page/Organization/New';
import OrganizationInvite from 'page/Organization/Invite';
import MyOrganization from 'page/MyOrganization/Single';
import ArticleList from 'page/Article/List';
import ArticleSingle from 'page/Article/Single';
import ArticleNew from 'page/Article/New';
import ClientList from 'page/Client/List';
import ClientSingle from 'page/Client/Single';
import ClientNew from 'page/Client/New';
import RegisterClient from 'page/Account/RegisterClient';
import NewClientUser from 'page/User/New/ClientUser';
import MyClient from 'page/MyClient/Single';
import ClientInvite from 'page/Client/Invite';
import DistributionList from 'page/DistributionUnits/List';
import DistributionUnitSingle from 'page/DistributionUnits/Single';
import DistributionUnitNew from 'page/DistributionUnits/New/New';
import NewDeviceDistributionUnit from 'page/Device/New/DuDevice';
import NewArticleDistributionUnit from 'page/DuArticle/New';
import ArticleDistributionUnitSingle from 'page/DuArticle/Single';
import DeviceSingle from 'page/Device/Single/DuSingle';
import DeviceList from 'page/Device/List';
import NewStandaloneDevice from 'page/Device/New/StandaloneDevice';
import StandaloneDeviceSingle from 'page/Device/Single/StandaloneSingle';
import LocationList from 'page/Location/List';
import LocationSingle from 'page/Location/Single';
import Login from 'page/Account/Login';
import LostPassword from 'page/Account/LostPassword';
import ResetPassword from 'page/Account/ResetPassword';
import ActivateAccount from 'page/Account/ActivateAccount';
import VerifyEmail from 'page/Account/VerifyEmail';
import RegisterOrganization from 'page/Account/RegisterOrganization';
import AccountSingle from 'page/MyAccount/Single';
import ChangePassword from 'page/MyAccount/ChangePassword';
import PickupList from 'page/Pickup/List';
import HandoverList from 'page/Handover/List';
import SupplyOverviewList from 'page/SupplyOverview/List';
import ArticleOverviewList from 'page/ArticleOverview/List';
import OperationSingle from 'page/Operation/Single';
import PackagesList from 'page/Package/List';
import TagsList from 'page/Tag/List';
import ForeignTagsList from 'page/ForeignTag/List';
import CurrenciesList from 'page/Currency/List';
import CurrencyNew from 'page/Currency/New';
import CurrencySingle from 'page/Currency/Single';
import MyBilling from 'page/MyBilling/Single';
import DeviceReaderNew from 'page/Reader/New';
import ReaderSingle from 'page/Reader/Single';
import LocationNew from 'page/Location/New';
import DriversList from 'page/Driver/List';
import DriverNew from 'page/Driver/New';
import DriverSingle from 'page/Driver/Single';
import ExpeditionList from 'page/Expedition/List';
import SuppliesList from 'page/Supplies/List';
import InHouseArticleList from 'page/InHouseArticle/List';
import MasterSettings from 'page/MasterSettings';
import InvoicesList from 'page/Invoice/List';
import InvoiceSingle from 'page/Invoice/Single';
import MyInvoicesList from 'page/MyInvoices/List';
import MyInvoiceSingle from 'page/MyInvoices/Single';
import ClientInvoicesList from 'page/ClientInvoices/List';
import ClientInvoicesSingle from 'page/ClientInvoices/Single';
import IntentsList from 'page/Intent/List';
import IntentSingle from 'page/Intent/Single';
import MyPaymentPlans from 'page/MyPaymentPlans/Single';
import PaymentPlansList from 'page/PaymentPlans/List';
import PaymentPlansNew from 'page/PaymentPlans/New';
import PaymentPlanSingle from 'page/PaymentPlans/Single';
import SecurityOverviewList from 'page/SecurityOverview/List';
import SecurityDetailsList from 'page/SecurityDetails/List';
import Logout from 'page/Account/Logout';
import CollectionList from 'page/Collection/List';
import HandoverCorrectionsList from 'page/HandoverCorrection/List';
import ExchangeRatesList from 'page/ExchangeRate/List';
import ExchangeRatesNew from 'page/ExchangeRate/New';
import ExchangeRatesSingle from 'page/ExchangeRate/Single';
import ReceptionList from 'page/Reception/List';
import AppVersionList from 'page/AppVersion/List';
import AppVersionNew from 'page/AppVersion/New';
import AppVersionView from 'page/AppVersion/Single';
import ActivityLogList from 'page/ActivityLog/List';
import ActivityLogView from 'page/ActivityLog/Single';
import OfflineLocationsList from 'page/OfflineLocations/List';
import NextExpeditionsList from 'page/NextExpeditions/List';
import SelfInviteClient from 'page/Account/SelfInviteClient';
import MissingArticlesList from 'page/MissingArticles/List';
import DuAccessLogs from 'page/DuAccessLog/List';
import DuAccessLogsView from 'page/DuAccessLog/Single';

type Route = {
  path: string;
  component: any;
  isPublic: boolean;
  isStrict?: boolean;
  pageProps?: any;
  layout?: any;
};

const router: Route[] = [
  // public routes
  { path: routes.login, component: Login, isPublic: true, isStrict: true },
  { path: routes.lost_password, component: LostPassword, isPublic: true, isStrict: true },
  { path: routes.reset_password, component: ResetPassword, isPublic: true, isStrict: true },
  { path: routes.activate_account, component: ActivateAccount, isPublic: true },
  { path: routes.verify_email, component: VerifyEmail, isPublic: true },
  { path: routes.register_organization, component: RegisterOrganization, isPublic: true },
  { path: routes.register_client, component: RegisterClient, isPublic: true },
  { path: routes.self_invite_client, component: SelfInviteClient, isPublic: true },
  // private routes
  { path: routes.home, component: Home, isPublic: false, },
  { path: routes.view_user, component: UserSingle, isPublic: false },
  { path: routes.new_user, component: UserNew, isPublic: false },
  { path: routes.new_organization_user, component: UserNewOrganizationUser, isPublic: false },
  { path: routes.list_users, component: UserList, isPublic: false },
  { path: routes.view_organization, component: OrganizationSingle, isPublic: false },
  { path: routes.new_organization, component: OrganizationNew, isPublic: false, },
  { path: routes.invite_organization, component: OrganizationInvite, isPublic: false, },
  { path: routes.my_account, component: AccountSingle, isPublic: false },
  { path: routes.change_password, component: ChangePassword, isPublic: false },
  { path: routes.list_organizations, component: OrganizationList, isPublic: false, },
  { path: routes.my_organization, component: MyOrganization, isPublic: false, },
  { path: routes.list_articles, component: ArticleList, isPublic: false, },
  { path: routes.view_article, component: ArticleSingle, isPublic: false, },
  { path: routes.new_article, component: ArticleNew, isPublic: false, },
  { path: routes.list_clients, component: ClientList, isPublic: false, },
  { path: routes.view_client, component: ClientSingle, isPublic: false, },
  { path: routes.new_client, component: ClientNew, isPublic: false, },
  { path: routes.new_client_user, component: NewClientUser, isPublic: false, },
  { path: routes.my_client, component: MyClient, isPublic: false, },
  { path: routes.invite_client, component: ClientInvite, isPublic: false, },
  { path: routes.list_distribution_units, component: DistributionList, isPublic: false, },
  { path: routes.view_distirbution_unit, component: DistributionUnitSingle, isPublic: false, },
  { path: routes.new_distribution_unit, component: DistributionUnitNew, isPublic: false, },
  { path: routes.list_locations, component: LocationList, isPublic: false, },
  { path: routes.view_location, component: LocationSingle, isPublic: false, },
  { path: routes.new_distribution_unit_device, component: NewDeviceDistributionUnit, isPublic: false, },
  { path: routes.view_du_device, component: DeviceSingle, isPublic: false, },
  { path: routes.list_devices, component: DeviceList, isPublic: false, },
  { path: routes.new_device, component: NewStandaloneDevice, isPublic: false, },
  { path: routes.view_standalone_device, component: StandaloneDeviceSingle, isPublic: false, },
  { path: routes.list_pickups, component: PickupList, isPublic: false, },
  { path: routes.list_handovers, component: HandoverList, isPublic: false, },
  { path: routes.view_supplies_operation, component: OperationSingle, isPublic: false, },
  { path: routes.list_supply_overview, component: SupplyOverviewList, isPublic: false, },
  { path: routes.list_article_overview, component: ArticleOverviewList, isPublic: false, },
  { path: routes.view_pickup_operation, component: OperationSingle, isPublic: false, },
  { path: routes.view_handover_operation, component: OperationSingle, isPublic: false, },
  { path: routes.view_expedition_operation, component: OperationSingle, isPublic: false, },
  { path: routes.view_collection_operation, component: OperationSingle, isPublic: false, },
  { path: routes.view_reception_operation, component: OperationSingle, isPublic: false, },
  { path: routes.view_missing_articles_operation, component: OperationSingle, isPublic: false, },
  { path: routes.list_packages, component: PackagesList, isPublic: false, },
  { path: routes.list_tags, component: TagsList, isPublic: false, },
  { path: routes.list_foreign_tags, component: ForeignTagsList, isPublic: false, },
  { path: routes.new_distribution_unit_article, component: NewArticleDistributionUnit, isPublic: false, },
  { path: routes.view_distribution_unit_article, component: ArticleDistributionUnitSingle, isPublic: false, },
  { path: routes.list_currencies, component: CurrenciesList, isPublic: false, },
  { path: routes.new_currency, component: CurrencyNew, isPublic: false, },
  { path: routes.view_currency, component: CurrencySingle, isPublic: false, },
  { path: routes.my_billing, component: MyBilling, isPublic: false, },
  { path: routes.new_standalone_device_reader, component: DeviceReaderNew, isPublic: false, },
  { path: routes.new_du_device_reader, component: DeviceReaderNew, isPublic: false, },
  { path: routes.view_device_reader, component: ReaderSingle, isPublic: false, },
  { path: routes.view_du_device_reader, component: ReaderSingle, isPublic: false, },
  { path: routes.new_location, component: LocationNew, isPublic: false, },
  { path: routes.list_drivers, component: DriversList, isPublic: false, },
  { path: routes.new_driver, component: DriverNew, isPublic: false, },
  { path: routes.view_driver, component: DriverSingle, isPublic: false, },
  { path: routes.list_expeditions, component: ExpeditionList, isPublic: false, },
  { path: routes.list_supplies, component: SuppliesList, isPublic: false, },
  { path: routes.list_in_house_articles, component: InHouseArticleList, isPublic: false, },
  { path: routes.master_settings, component: MasterSettings, isPublic: false, },
  { path: routes.list_invoices, component: InvoicesList, isPublic: false, },
  { path: routes.view_invoice, component: InvoiceSingle, isPublic: false, },
  { path: routes.list_my_invoice, component: MyInvoicesList, isPublic: false, },
  { path: routes.view_my_invoice, component: MyInvoiceSingle, isPublic: false, },
  { path: routes.list_client_invoices, component: ClientInvoicesList, isPublic: false, },
  { path: routes.view_client_invoice, component: ClientInvoicesSingle, isPublic: false, },
  { path: routes.list_intents, component: IntentsList, isPublic: false, },
  { path: routes.view_intent, component: IntentSingle, isPublic: false, },
  { path: routes.payment_plans, component: MyPaymentPlans, isPublic: false, },
  { path: routes.list_payment_plans, component: PaymentPlansList, isPublic: false, },
  { path: routes.new_payment_plan, component: PaymentPlansNew, isPublic: false, },
  { path: routes.view_payment_plan, component: PaymentPlanSingle, isPublic: false, },
  { path: routes.list_security_overview, component: SecurityOverviewList, isPublic: false, },
  { path: routes.list_security_details, component: SecurityDetailsList, isPublic: false, },
  { path: routes.logout, component: Logout, isPublic: false, },
  { path: routes.list_collection, component: CollectionList, isPublic: false, },
  { path: routes.list_handover_corrections, component: HandoverCorrectionsList, isPublic: false, },
  { path: routes.list_exchange_rates, component: ExchangeRatesList, isPublic: false, },
  { path: routes.new_exchange_rate, component: ExchangeRatesNew, isPublic: false, },
  { path: routes.view_exchange_rate, component: ExchangeRatesSingle, isPublic: false, },
  { path: routes.list_reception, component: ReceptionList, isPublic: false, },
  { path: routes.list_app_version, component: AppVersionList, isPublic: false, },
  { path: routes.new_app_version, component: AppVersionNew, isPublic: false, },
  { path: routes.view_app_version, component: AppVersionView, isPublic: false, },
  { path: routes.list_activity_logs, component: ActivityLogList, isPublic: false, },
  { path: routes.view_activity_log, component: ActivityLogView, isPublic: false, },
  { path: routes.list_offline_locations, component: OfflineLocationsList, isPublic: false, },
  { path: routes.list_next_expeditions, component: NextExpeditionsList, isPublic: false, },
  { path: routes.list_missing_articles, component: MissingArticlesList, isPublic: false, },
  { path: routes.list_du_access_logs, component: DuAccessLogs, isPublic: false, },
  { path: routes.view_du_access_log, component: DuAccessLogsView, isPublic: false, },
];

export default router;
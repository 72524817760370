import React, { useState } from "react";
import { Box, Button, CardContent, TableBody, TableCell, TableRow } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import EditableCard from "component/styled/EditableCard";
import User from "model/user";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CardTitleBar from "component/common/CardTitleBar";
import TableNoBb from "component/styled/TableNoBb";
import { changeAccessCode } from "helper/backend";
import { showError, showSuccess } from "helper/util";
import Preloader from "component/common/Preloader";
import QRCode from "react-qr-code"
import { downloadQrCode } from "helper/qr";
import GridContainer from "component/common/GridContainer";
import ConfirmDialog from "component/common/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { Visibility } from "@mui/icons-material";

type Props = {
  user: User;
  refreshHandler: Function;
};

const SectionAccess = ({ user, refreshHandler }: Props) => {

  const { t } = useTranslation();

  // whether the chagning confirmation is visible
  const [isChangeConfOpen, setIsChangeConfOpen] = useState(false);
  // whether the changing of the code is in progress
  const [isChangeInProgress, setIsChangeInProgress] = useState(false);
  // whether the access code is shown
  const [isAccessShown, setIsAccessShown] = useState(false);


  /**
  * Event handler called whenever the user changes the code
  */
  const refreshAccessCode = () => {
    setIsChangeInProgress(true);
    changeAccessCode()
      .then(_response => {
        showSuccess(t("accessCodeHasChange"));
        refreshHandler();
      })
      .catch(_ex => {
        showError(t("accessCodeUnableChange"));
      })
      .finally(() => {
        setIsChangeInProgress(false);
      });
  };

  /**
  * Event handler called whenever the user download the QR code
  */
  const handleDownload = async () => {
    const svg = document.getElementById("qr-code") as HTMLElement;
    downloadQrCode(svg);
  }

  /**
  * Event handler called whenever the user clicks on show access info
  */
  const toggleShowAccess = () => {
    setIsAccessShown(true);
    setTimeout(() => {
      setIsAccessShown(false);
    }, 30000)
  }

  return (
    <>
      <EditableCard sx={{ height: '100%' }}>
        <CardContent>
          <CardTitleBar title={"Access"}>
            <Button variant="contained" color="secondary" startIcon={<RefreshIcon />} onClick={() => setIsChangeConfOpen(true)}>{t("changeAccessCode")}</Button>
          </CardTitleBar>
          <TableNoBb sx={{ overflow: "hidden" }}>
            <TableBody>
              <TableRow>
                <TableCell>{t("accessCode")}</TableCell>
                <TableCell className="preview-value" sx={{ position: "relative", fontSize: "21px" }}>
                  {!isAccessShown && <Box className="access-blur-code"></Box>}
                  {user?.accessCode || "--"}
                </TableCell>
              </TableRow>
              {user?.accessCode &&
                <TableRow sx={{ width: { xs: "100%" } }}>
                  <TableCell sx={{ display: { xs: 'none', lg: 'table-cell' } }}>{t("qrCode")}</TableCell>
                  <TableCell className="preview-value" colSpan={2}>
                    <GridContainer sx={{ justifyContent: { xs: "center", lg: "start" } }}>
                      <Grid xs={12}
                        sx={{
                          mt: 5,
                          position: "relative",
                          width: { xs: "71%", lg: "235px" },
                          height: { xs: "71%", lg: "235px" },
                        }}>
                        {!isAccessShown && <Box className="access-blur-qr">
                          <Button variant="contained" color="primary" size="large" onClick={toggleShowAccess} sx={{ boxShadow: "0 0px 40px 0px #fff" }} startIcon={<Visibility />}> {t("showAccessCode")}</Button>
                        </Box>}
                        <QRCode
                          id="qr-code"
                          value={user?.accessCode!.toString() || ""}
                          size={400}
                          style={{
                            height: "100%",
                            width: "100%"
                          }}
                        />
                      </Grid>
                      <Grid xs={12} sx={{ display: { xs: "flex" }, justifyContent: { xs: "center", lg: "flex-start" }, mt: 1 }}>
                        {!isAccessShown && <Box className="access-blur-download"></Box>}
                        <Button variant="contained" color="primary" onClick={handleDownload} startIcon={<DownloadIcon />}>{t("downloadQrCode")}</Button>
                      </Grid>
                    </GridContainer>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </TableNoBb>
          {isChangeInProgress && <Preloader container="parent" />}
        </CardContent>
      </EditableCard>
      <ConfirmDialog
        isOpen={isChangeConfOpen}
        yesButton={t("confirm")}
        onConfirm={() => {
          setIsChangeConfOpen(false);
          refreshAccessCode();
        }}
        onCancel={() => {
          setIsChangeConfOpen(false);
        }}
      >
        {t("accessCodeConfirmation")}
      </ConfirmDialog>
    </>
  )
}

export default SectionAccess;
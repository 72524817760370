import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';
import { getClientList } from 'helper/backend';
import Client from 'model/client';
import MuiAutocomplete from 'component/common/MuiAutocomplete';
import DateRangeInput from 'component/common/DateRangeInput';
import Invoice from 'model/invoice';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, setFieldValue, setFieldError }: Props) => {

  const { t } = useTranslation();

  // list of clients that populate the select field
  const [clients, setClients] = useState<Client[]>([]);

  /**
   * Converts the list of clients to select options
   */
  const getClientOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = clients.map(cli => ({ label: cli.companyName, value: cli.id }));
    options.push(...orgs);
    return options;
  }, [clients, t]);

  /**
  * Converts the list of types to select options
  */
  const getTypesOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = Invoice.InvoiceType.filter((inv) => inv.id !== Invoice.TYPE_SUBSCRIPTION).map(invoice => ({ label: invoice.name, value: invoice.id }));
    options.push(...orgs);
    return options;
  }, [t]);

  /**
   * Fetches from the backend the list of clients this user is allowed to see
   */
  const fetchClients = useCallback(() => {
    getClientList()
      .then(response => {
        setClients(response.clients);
      })
      .catch(_ex => {
        setFieldError('clientId', t("unableToLoadClients"));
      });
  }, [setFieldError, t]);

  // This hook runs once on component mount
  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('clientId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('clientId');
              }}
              value={getClientOptions().find(option => option.value === +values.clientId) || getClientOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getClientOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("client")} error={!!errors.clientId} helperText={errors.clientId} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <DateRangeInput value={values.createdTs} errors={errors} setStatus={setStatus} setFieldValue={(value) => setFieldValue('createdTs', value)} />
          </Grid>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('type', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('type');
              }}
              value={getTypesOptions().find(option => option.value === +values.type) || getTypesOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getTypesOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("type")} error={!!errors.type} helperText={errors.type} />}
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;
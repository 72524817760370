import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useTranslation } from 'react-i18next';
import { getDriversList, getExpeditionLocationsList } from 'helper/backend';
import Location from 'model/location';
import User from 'model/user';
import MuiAutocomplete from 'component/common/MuiAutocomplete';
import DateRangeInput from 'component/common/DateRangeInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, setFieldValue, setFieldError }: Props) => {

  const { t } = useTranslation();

  const [locations, setLocations] = useState<Location[]>([]);
  const [drivers, setDrivers] = useState<User[]>([]);

  /**
  * Fetches from the backend the list of organizations this user is allowed to see
  */
  const fetchLocations = useCallback(() => {
    getExpeditionLocationsList()
      .then(response => {
        setLocations(response.locations);
      })
      .catch(_ex => {
        setFieldError('locationId', t("unableToLoadLocations"));
      });
  }, [setFieldError, t]);

  /**
* Fetches from the backend the list of organizations this user is allowed to see
*/
  const fetchDrivers = useCallback(() => {
    getDriversList()
      .then(response => {
        setDrivers(response.drivers);
      })
      .catch(_ex => {
        setFieldError('driverId', t("unableToLoadDrivers"));
      });
  }, [setFieldError, t]);

  /**
 * Converts the list of drivers to select options
 */
  const getDriverOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = drivers.map(driver => ({ label: driver.fullName, value: driver.id }));
    options.push(...orgs);
    return options;
  }, [drivers, t]);

  /**
   * Converts the list of locations to select options
   */
  const getLocationOptions = useCallback(() => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = locations.map(location => ({ label: location.name, value: location.id }));
    options.push(...orgs);
    return options;
  }, [locations, t]);

  useEffect(() => {
    fetchLocations();
    fetchDrivers();
  }, [fetchLocations, fetchDrivers])

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('locationId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('locationId');
              }}
              value={getLocationOptions().find(option => option.value === +values.locationId) || getLocationOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getLocationOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("location")} error={!!errors.locationId} helperText={errors.locationId} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('driverId', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('driverId');
              }}
              value={getDriverOptions().find(option => option.value === +values.driverId) || getDriverOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getDriverOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("drivers")} error={!!errors.driverId} helperText={errors.driverId} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <DateRangeInput value={values.createdTs} errors={errors} setStatus={setStatus} setFieldValue={(value) => setFieldValue('createdTs', value)} />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;
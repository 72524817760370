import React, { useState } from "react";
import { Box, Button, CardContent } from "@mui/material";
import EditableCard from "component/styled/EditableCard";
import CardTitleBar from "component/common/CardTitleBar";
import QRCode from "react-qr-code"
import { useTranslation } from "react-i18next";
import { Visibility } from "@mui/icons-material";

type Props = {
  accessCode: number;
}

const Access = ({ accessCode }: Props) => {

  const { t } = useTranslation();

  // whether the access code is shown
  const [isAccessShown, setIsAccessShown] = useState(false);

  /**
  * Event handler called whenever the user clicks on show access info
  */
  const toggleShowAccess = () => {
    setIsAccessShown(true);
    setTimeout(() => {
      setIsAccessShown(false);
    }, 30000)
  }

  return (
    <>
      <EditableCard sx={{ height: '360px' }}>
        <CardContent>
          <CardTitleBar title={"Access"} />
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Box className="preview-value" sx={{ position: "relative", fontSize: "21px", fontWeight: 600 }}>
              {!isAccessShown && <Box className="dashboard-access-blur-code"></Box>}
              {accessCode}
            </Box>
            <Box
              sx={{
                mt: 3,
                position: "relative",
                width: "210px",
                height: "210px",
              }}
            >
              {!isAccessShown && <Box className="dashboard-access-blur-qr">
                <Button variant="contained" color="primary" size="large" onClick={toggleShowAccess} sx={{ boxShadow: "0 0px 40px 0px #fff" }} startIcon={<Visibility />}> {t("showAccessCode")}</Button>
              </Box>}
              <QRCode
                id="qr-code"
                value={accessCode.toString() || ""}
                size={400}
                style={{
                  height: "100%",
                  width: "100%"
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </EditableCard>
    </>
  )
}

export default Access;
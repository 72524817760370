import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import { getOwnBilling } from "helper/backend";
import { toSimpleError } from "helper/util";
import { AppError } from "@type";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import { Billing } from "@type/backend";
import SectionBilling from "./Partial/SectionBilling";
import SectionInfo from "./Partial/SectionInfo";
import SectionSettings from "./Partial/SectionSettings";

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  // the billing record fetched from the backend
  const [billingInfo, setBillingInfo] = useState<Billing | undefined>()
  // error encoutered while fetching the billing (if any)
  const [billingError, setBillingError] = useState<AppError | undefined>();
  // whether the loading of the billing is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)

  /**
  * Fetches the billing record from the backend
  */
  const refreshBilling = useCallback(() => {
    setIsLoadInProgress(true)
    getOwnBilling()
      .then(response => {
        setBillingInfo(response.master);
      })
      .catch(ex => {
        setBillingError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [])

  // This hook runs once on component mount
  useEffect(() => {
    refreshBilling();
  }, [refreshBilling]);

  return (
    <>
      {isGranted(perms.view_master)
        &&
        <>
          {
            !!billingInfo
            &&
            <Box>
              <PageTitleBar title={t("myBillingInfo")} />
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo billingInfo={billingInfo} refreshHandler={refreshBilling} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionBilling billingInfo={billingInfo} refreshHandler={refreshBilling} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionSettings billingInfo={billingInfo} refreshHandler={refreshBilling} isLoadInProgress={isLoadInProgress} />
                </Grid>
              </GridContainer>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !billingInfo && <Preloader container="content" />}
          {!!billingError && <Error error={billingError} title404={t("billingNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_master) && <AccessDenied />}
    </>
  )
}

export default Single;
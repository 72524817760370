import React, { useCallback, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Box, CardContent } from "@mui/material";
import WizardStepper from "./WizardStepper";
import GridContainer from "component/common/GridContainer";
import EditableCard from "component/styled/EditableCard";
import { createPaymentCustomer, createPaymentSetupIntent } from "helper/backend";
import { showError, toSimpleError } from "helper/util";
import Preloader from "component/common/Preloader";
import PaymentForm from "../Form/PaymentForm";
import { useTranslation } from "react-i18next";
import AccessRole from "model/accessRole";
import { useAuth } from "context/auth";
import { STRIPE_CUSTOMER_ID_ALREADY_SET } from "helper/error";
import background from 'asset/image/bgPattern.png';
import logo from 'asset/image/logo.png';

const Payment = () => {

  const { t } = useTranslation();
  const { isLoggedIn, authUser } = useAuth();

  // saves the payment intent
  const [paymentIntent, setPaymentIntent] = useState<any>();
  // whether the load of the data is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);
  // holds the customerId such that we know that this client has a customer id created
  const [customerId, setCustomerId] = useState();

  /**
  * Event handler called at first render to create a payment intent
  */
  const createPaymentIntent = useCallback(() => {
    setIsLoadInProgress(true)
    createPaymentSetupIntent()
      .then((response: any) => {
        setPaymentIntent(response.setupIntent)
      })
      .catch(_ex => {
        showError(t("unableToCreatePaymentIntent"));
      })
      .finally(() => {
        setIsLoadInProgress(false)
      });
  }, [t]);

  /**
  * Event handler called at first render to create stripe customer for clients only
  */
  const createCustomer = useCallback(() => {
    createPaymentCustomer()
      .then((response: any) => {
        setCustomerId(response)
      })
      .catch(ex => {
        const err = toSimpleError(ex)
        if (err.code === STRIPE_CUSTOMER_ID_ALREADY_SET) {
          createPaymentIntent();
        } else {
          showError(t("unableToCreateCustomer"));
        }
      })
  }, [t, createPaymentIntent]);

  /**
  * Hook to create payment intent for organization and client
  */
  useEffect(() => {
    // Don't do nothing unil we have the customerId
    if (!customerId) {
      return;
    }

    createPaymentIntent();
  }, [createPaymentIntent, customerId, isLoggedIn])

  /**
  * Hook to create customer for client ONLY, organization creates its
  * customer in a previous step
  */
  useEffect(() => {
    if (isLoggedIn()) {
      createCustomer();
    } else {
      return;
    }
  }, [createCustomer, isLoggedIn])

  const getStripeOptions = () => ({
    clientSecret: paymentIntent?.clientSecret,
  });

  return <React.Fragment>
    <Box sx={{ display: 'flex', height: '100vh', width: "100%", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <WizardStepper activeStep={authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION ? 1 : 0} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          marginTop: { xs: 0, md: "-64px" },
          width: "100%",
          '@media (max-height: 800px)': {
            marginTop: 0
          }
        }}>
        <GridContainer
          sx={{
            width: { sx: "100%", md: "600px" },
            margin: { xs: 0, md: "auto" },
            height: { xs: "100%", md: "auto" },
            '@media (max-height: 800px)': {
              width: "100%",
              margin: 0,
              height: "100%"
            }
          }}
        >
          <Grid xs={12} sx={{ padding: { xs: 0 } }}>
            {!!paymentIntent &&
              <EditableCard sx={{ height: '100%', padding: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CardContent>
                  <Box sx={{ textAlign: 'center', mb: 6, height: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                      <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </Box>
                  </Box>
                  {
                    authUser.isClientType() &&
                    <Box sx={{ mb: 4, textAlign: "center" }}>
                      The credit card information is required to securely process <b>future payments</b>. At this stage, <b>no money will be withdrawn from your account</b>. Charges will only be made when you complete a rental and receive your items.
                    </Box>
                  }
                  <PaymentForm options={getStripeOptions()} />
                </CardContent>
              </EditableCard>
            }
          </Grid>
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && <Preloader container="parent" />}
        </GridContainer>
      </Box>
    </Box>
  </React.Fragment >
}

export default Payment;
import React, { useCallback, useState, FocusEvent } from "react";
import { Check, Close, DateRange, KeyboardArrowLeft } from '@mui/icons-material';
import { DateRangePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Box, Button, Dialog, IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatTimestamp, formats } from "helper/date";
import theme from "theme";

type DateRangeProps = {
  value: string,
  errors: any,
  setStatus: Function,
  setFieldValue: (value: string) => Promise<any>;
  disableClear?: boolean;
}

const DateRangeInput = ({ value, errors, setStatus, setFieldValue, disableClear }: DateRangeProps) => {

  const { t } = useTranslation();
  const [pickerDate, setPickerDate] = useState<any>([
    {
      startDate: !!value.length ? new Date(+value.split("-")[0] * 1000) : new Date(),
      endDate: !!value.length ? new Date(+value.split("-")[1] * 1000) : addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [toggleDate, setToggleDate] = useState(false);

  const saveDate = () => {
    let formattedDate = `${moment(pickerDate[0].startDate).unix()}-${moment(pickerDate[0].endDate).unix()}`
    window.blur()
    setFieldValue(formattedDate)
    setToggleDate(false);
  };

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return (
    <>
      <TextField
        name="date"
        label={t("date")}
        variant="outlined"
        onFocus={onTextFieldFocused}
        onClick={() => setToggleDate(true)}
        value={!!value ? `${formatTimestamp(+value.split("-")[0], formats.LONG_DATE)}-${formatTimestamp(+value.split("-")[1], formats.LONG_DATE)}` : ""}
        error={!!errors.createdTs} helperText={errors.createdTs} sx={{ width: '100%' }}
        InputProps={{
          startAdornment: <InputAdornment position="start"><DateRange /></InputAdornment>, endAdornment: !disableClear ? <IconButton onClick={(e) => { e.stopPropagation(); setFieldValue("") }}><Close /></IconButton> : undefined,
          readOnly: true
        }}
      />
      <Dialog open={toggleDate} onClose={() => setToggleDate(false)} fullScreen={useMediaQuery(theme.breakpoints.down('md'))} maxWidth={false} >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: 3 }}>
          <DateRangePicker
            onChange={item => setPickerDate([item.selection])}
            months={2}
            direction='horizontal'
            ranges={pickerDate}
            moveRangeOnFirstSelection={false}
            rangeColors={["#33AD93"]}
          />
          <Box sx={{ alignSelf: "flex-end", mt: 1 }}>
            <Button variant="contained" color="secondary" onClick={() => setToggleDate(false)} sx={{ mr: 1 }} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
            <Button name="saveDate" variant="contained" onClick={saveDate} color="primary" startIcon={<Check />}>{t("save")}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default DateRangeInput;
import React, { ChangeEvent, useCallback, } from 'react';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import User from 'model/user';
import { useTranslation } from 'react-i18next';
import { getYesNoOptions } from 'helper/util';
import Device from 'model/device';
import DateRangeInput from 'component/common/DateRangeInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
  authUser: User;
};

const Filters = ({ values, errors, setStatus, setFieldValue }: Props) => {

  const { t } = useTranslation();

  /**
  * Converts the list of types to select options
  */
  const getTypeOptions = () => {
    const options = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    const orgs = Device.DeviceTypes.map(type => ({ label: type.name, value: type.id }))
    options.push(...orgs);
    return options;
  }

  /**
  * Returns the selected entity types as a select option
  */
  const getSelectedTypeOption = () => getTypeOptions().find(option => option.value === values.deviceType) || null;


  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('deviceType', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('deviceType');
              }}
              value={getSelectedTypeOption()}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getTypeOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("deviceType")} error={!!errors.deviceType} helperText={errors.deviceType} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('isActive', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('isActive');
              }}
              value={getYesNoOptions(t("all")).find(option => option.value === +values.isActive)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getYesNoOptions(t("all"))}
              renderInput={(params: any) => <TextField {...params} label={t("active")} error={!!errors.isActive} helperText={errors.isActive} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('isBeta', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('isBeta');
              }}
              value={getYesNoOptions(t("all")).find(option => option.value === +values.isBeta)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getYesNoOptions(t("all"))}
              renderInput={(params: any) => <TextField {...params} label={t("beta")} error={!!errors.isBeta} helperText={errors.isBeta} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <DateRangeInput value={values.createdTs} errors={errors} setStatus={setStatus} setFieldValue={(value) => setFieldValue('createdTs', value)} />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;
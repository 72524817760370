import React, { useCallback, useEffect, useState } from "react";
import { Box, CardContent, Link, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CardTitleBar from "component/common/CardTitleBar";
import Preloader from "component/common/Preloader";
import EditableCard from "component/styled/EditableCard";
import { getNextHandover } from "helper/backend";
import { useTranslation } from "react-i18next";
import { buildOperationNumber, showDaysText, showError, toSimpleError } from "helper/util";
import TableNoBb from "component/styled/TableNoBb";
import { Handover } from "@type/backend";
import { OPERATION_NOT_FOUND } from "helper/error";
import { formatTimestamp, formats } from "helper/date";
import { route, routes } from "helper/route";
import { Warning } from "@mui/icons-material";

const NextHandover = () => {
  const { t } = useTranslation();

  // Holds the next handovers received from backend
  const [nextHandover, setNextHandover] = useState<Handover>()
  // whenever we fetch the record
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  /**
  * Fetches the record from the backend
  */
  const fetchNextHandover = useCallback(() => {
    setIsLoadInProgress(true)
    getNextHandover()
      .then(response => {
        setNextHandover(response);
      })
      .catch(ex => {
        const err = toSimpleError(ex)
        if (err.code !== OPERATION_NOT_FOUND) {
          showError(t("unableToLoadNextHandover"));
        }
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [t]);

  useEffect(() => {
    fetchNextHandover();
  }, [fetchNextHandover])

  return (
    <EditableCard sx={{ height: "744px", overflowY: "auto" }}>
      <CardContent>
        <CardTitleBar title={!!nextHandover ? `${t("nextHandoverBy")} ${formatTimestamp(nextHandover.handoverBy, formats.DATETIME)}` : t("nextHandover")} />
        {
          !!nextHandover
            ?
            <>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                  {t("remainingTime")} :
                </Typography>
                {
                  nextHandover.handoverByDays > 0
                    ?
                    <Typography component="span" sx={{ display: "inline", fontSize: "0.960rem" }}>
                      {nextHandover.handoverByDays >= 5
                        ?
                        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                          {nextHandover.handoverByDays} {t(showDaysText(nextHandover.handoverByDays))}
                        </Box>
                        :
                        <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                          {nextHandover.handoverByDays} {t(showDaysText(nextHandover.handoverByDays))}
                          <Warning color="warning" />
                        </Box>
                      }
                    </Typography>
                    :
                    <Typography component="span" sx={{ display: "inline", fontSize: "0.960rem" }} >
                      <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                        {t("today")}
                        <Warning color="warning" />
                      </Box>
                    </Typography>
                }
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                  {t("number")} :
                </Typography>
                <Typography component={Link} href={route(routes.view_pickup_operation, nextHandover.operationId)} sx={{ display: "inline", fontSize: "0.960rem" }}>{buildOperationNumber(nextHandover.operationId, nextHandover.type, nextHandover.pickupDateTs)}</Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography sx={{ fontWeight: 500, fontSize: "0.960rem", display: "inline", mr: 1 }}>
                  {t("pickupDate")} :
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "0.960rem" }}>{formatTimestamp(nextHandover.pickupDateTs, formats.DATETIME)}</Typography>
              </Box>
              <Box sx={{ height: "400px", mt: 0.5 }}>
                <TableNoBb sx={{ mt: 3 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("article")}</TableCell>
                      <TableCell>{t("noOfItems")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      nextHandover.items.map((el, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell>{el.name} ({el.code})</TableCell>
                            <TableCell>{el.items}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </TableNoBb>
              </Box>
            </>
            :
            <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {t("noHandoverFound")}
            </Box>
        }
      </CardContent>
      {isLoadInProgress && <Preloader container="parent" />}
    </EditableCard >
  )
};

export default NextHandover;
import React, { ChangeEvent, useCallback } from 'react';
import { Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import DateRangeInput from 'component/common/DateRangeInput';
import MuiAutocomplete from 'component/common/MuiAutocomplete';
import Invoice from 'model/invoice';
import { useTranslation } from 'react-i18next';

type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setStatus, setFieldValue }: Props) => {

  const { t } = useTranslation();

  /**
  * Converts the list of types to select options
  */
  const getTypesOptions = useCallback(() => {
    const options = [];
    let types = [];
    options.push({ label: t("all"), value: Number.MIN_SAFE_INTEGER });
    types = Invoice.InvoiceType.filter((inv) => inv.id === Invoice.TYPE_SUBSCRIPTION || inv.id === Invoice.TYPE_FOREIGN_TAGS).map(invoice => ({ label: invoice.name, value: invoice.id }));
    options.push(...types);
    return options;
  }, [t]);


  /**
  * Event handler called whenever the user focuses a form select field
  */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <DateRangeInput value={values.createdTs} errors={errors} setStatus={setStatus} setFieldValue={(value) => setFieldValue('createdTs', value)} />
          </Grid>
          <Grid xs={12} md={3}>
            <MuiAutocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('type', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('type');
              }}
              value={getTypesOptions().find(option => option.value === +values.type) || getTypesOptions()[0]}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getTypesOptions()}
              renderInput={(params: any) => <TextField {...params} label={t("type")} error={!!errors.type} helperText={errors.type} />}
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, CardContent, Typography } from "@mui/material";
import WizardStepper from "./WizardStepper";
import GridContainer from "component/common/GridContainer";
import EditableCard from "component/styled/EditableCard";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { assignPaymentPlanOnboarding, getMyPaymentPlans } from "helper/backend";
import Preloader from "component/common/Preloader";
import withStripeElements from "hoc/stripeElements";
import { Done, NavigateNext, TaskAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import background from 'asset/image/bgPattern.png';
import PaymentPlan from "model/paymentPlan";
import { showError, toSimpleError } from "helper/util";
import { AppError } from "@type";
import Error from 'page/Error';
import logo from 'asset/image/logo.png';
import { formatCurrency } from "helper/currency";

const PaymentPlans = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // the payment plan record fetched from the backend
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[] | undefined>()
  // error encoutered while fetching the payment plan (if any)
  const [paymentPlanError, setPaymentPlanerror] = useState<AppError | undefined>();
  // the payment plan selected by the user
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState<number>();
  // whenever stripe is in progress
  const [isStripeInProgress, setIsStripeInProgress] = useState(false);
  // whether the loading of the plan change is in progress
  const [isPlanChangeInProgress, setIsPlanChangeInProgress] = useState<boolean>(false)

  const selectPaymentPlan = (id: number) => {
    setIsPlanChangeInProgress(true)
    assignPaymentPlanOnboarding(id)
      .then((_response: any) => {
        navigate("/onboarding/finish");
      })
      .catch(_ex => {
        showError(t("unableToSelectPaymentPlan"));
      })
      .finally(() => {
        setIsPlanChangeInProgress(false)
      })
  }

  /**
  * Fetches the payment plan record from the backend
  */
  const refreshPaymentPlans = useCallback(() => {
    setIsStripeInProgress(true)
    getMyPaymentPlans()
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(ex => {
        setPaymentPlanerror(toSimpleError(ex));
      })
      .finally(() => {
        setIsStripeInProgress(false);
      })
  }, [])

  useEffect(() => {
    refreshPaymentPlans();
  }, [refreshPaymentPlans])

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: "100%", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', backgroundColor: "#343b4a", backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <WizardStepper activeStep={2} />
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <GridContainer sx={{ width: "100%", margin: "auto" }}>
          <Grid xs={12} sx={{ padding: { xs: 0 } }}>
            {!!paymentPlans
              &&
              (
                <EditableCard
                  sx={{
                    display: "flex",
                    gap: "24px",
                    p: 2,
                    flexDirection: "column",
                    padding: { xs: 0 },
                    height: "80vh",
                    width: "80vw",
                    overflow: "auto",
                    '@media (max-width: 900px)': {
                      width: "100vw",
                      height: "100vh"
                    },
                  }}>
                  <CardContent sx={{ height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                    <Box sx={{ textAlign: 'center', mb: 3, height: "100px", width: "100%", display: "flex", justifyContent: "center" }}>
                      <Box sx={{ lineHeight: 1, height: "100%", display: "flex", alignItems: "center" }}>
                        <img src={logo} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                      </Box>
                    </Box>
                    <Box sx={{ mb: 0, textAlign: "center" }}>
                      {t("onboardingPaymentPlans")}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ display: "flex", p: 2, gap: "50px", overflowX: "auto", scrollSnapType: "x mandatory" }}>
                        {
                          paymentPlans?.map((paymentPlan: PaymentPlan, idx: number) => {
                            return (
                              <EditableCard key={idx} sx={{ backgroundColor: "#efefef", overflow: "initial", width: "350px", height: "480px", display: "flex", flexDirection: "column", flex: "0 0 100%", scrollSnapAlign: "start", transition: "all 0.2s", flexBasis: "350px" }} >
                                {paymentPlan.id === selectedPaymentPlan &&
                                  <div className="ribbon ribbon-top-left"><span>{t("active")}</span></div>
                                }
                                <Box sx={{ backgroundColor: paymentPlan.id === selectedPaymentPlan ? "#33AD93" : "#414d5f", mt: 3, textAlign: "center", padding: "16px", color: "#FFFFFF", fontSize: "17px", textTransform: "uppercase" }}>
                                  {paymentPlan.name}
                                </Box>
                                <Box sx={{ backgroundColor: "#efefef", display: "flex", flexDirection: "column", alignItems: "center", pb: 2 }}>
                                  <Box sx={{ textAlign: "center", fontSize: "32px", fontWeight: 500, mt: 3 }}>
                                    {formatCurrency(paymentPlan.price, paymentPlan.currencySymbol, paymentPlan.currencyPosition, paymentPlan.currencyHasSpacing!)}
                                    <Box sx={{ fontSize: "17px", fontWeight: 400 }}>
                                      {t("perMonth")}
                                    </Box>
                                  </Box>
                                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", margin: "0 auto" }}>
                                    <Box sx={{ textAlign: "center", fontSize: "16px", mt: 5, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                                      <TaskAlt color={paymentPlan.id === selectedPaymentPlan ? "primary" : "secondary"} /> {t("upto")} {paymentPlan.tagLimit} {t("tags")}
                                    </Box>
                                    <Box sx={{ textAlign: "center", fontSize: "16px", mt: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                                      <TaskAlt color={paymentPlan.id === selectedPaymentPlan ? "primary" : "secondary"} /> {t("upto")} {paymentPlan.clientLimit} {t("clientsPlan")}
                                    </Box>
                                    <Box sx={{ textAlign: "center", fontSize: "16px", mt: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                                      <TaskAlt color={paymentPlan.id === selectedPaymentPlan ? "primary" : "secondary"} /> {t("upto")} {paymentPlan.userLimit} {t("usersPlan")}
                                    </Box>
                                    <Box sx={{ textAlign: "center", fontSize: "15px", mt: 4, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px", fontStyle: "italic" }}>
                                      {t("foreignTagFee")}: {formatCurrency(paymentPlan.foreignTagFee, paymentPlan.currencySymbol, paymentPlan.currencyPosition, paymentPlan.currencyHasSpacing!)}
                                    </Box>
                                  </Box>
                                  <Box sx={{ alignSelf: "center", mt: 4 }}>
                                    {
                                      paymentPlan.id === selectedPaymentPlan
                                        ?
                                        <Typography color="primary" sx={{ fontWeight: 600, mt: 2, fontSize: "18px", mb: 1 }}>
                                          {t("selected")}
                                        </Typography>
                                        :
                                        <Button variant="contained" size="large" color="secondary" sx={{ fontSize: "16px", backgroundColor: "#414d5f", mb: 1 }} onClick={() => setSelectedPaymentPlan(paymentPlan.id)} startIcon={<Done />}>{t("select")}</Button>
                                    }
                                  </Box>
                                </Box>
                              </EditableCard>
                            )
                          })
                        }
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", pr: "18px", pt: 2, pb: 2 }}>
                      <Button variant="contained" size="large" color="primary" onClick={() => selectPaymentPlan(selectedPaymentPlan!)} disabled={!selectedPaymentPlan} startIcon={<NavigateNext />}>{t("next")}</Button>
                    </Box>
                  </CardContent>
                </EditableCard>
              )
            }
          </Grid>
        </GridContainer>
      </Box>
      {!!paymentPlanError && <Error error={paymentPlanError} title404={t("paymentPlansNotFound")} />}
      {/* Show the prealoder only on the first fetch */}
      {isStripeInProgress && <Preloader container="parent" />}
      {isPlanChangeInProgress && <Preloader container="parent" />}
    </Box >
  )
}

export default withStripeElements(PaymentPlans);
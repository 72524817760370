import { combineReducers } from 'redux';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  APPLY_FOREIGN_TAG_DT_PARAMS,
  PATCH_FOREIGN_TAG_DT_PARAMS
} from './actionTypes';
import { ForeignTagDtRequestActionPayload } from './types';
import { DtParams } from '@type';
import { getDefaultDtParams } from 'helper/dt';

/********** LOGIN **********/

const defaultDtParamsState: DtParams = getDefaultDtParams();

const DtRequest = (state = defaultDtParamsState, action: PayloadAction<ForeignTagDtRequestActionPayload>): DtParams => {
  switch (action.type) {
    case APPLY_FOREIGN_TAG_DT_PARAMS:
      return {
        ...action.payload.params
      };
    case PATCH_FOREIGN_TAG_DT_PARAMS:
      return {
        ...state,
        ...action.payload.params,
        filters: {
          ...state.filters,
          ...action.payload.params.filters
        }
      }
    default:
      return state;
  }
}

export default combineReducers({
  DtRequest,
});

import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "context/auth";
import AccessRole from "model/accessRole";

type Props = {
  activeStep: number;
}

const WizardStepper = ({ activeStep }: Props) => {

  const { t } = useTranslation();
  const { authUser } = useAuth();

  return (
    <Stepper
      activeStep={activeStep}
      sx={{
        height: "60px",
        padding: "64px 30% 0 30%",
        fontSize: "18px",
        width: "100%",
        display: { xs: "none", md: "flex" },
        '@media (max-height: 800px)': {
          display: "none",
        }
      }}>
      {authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION &&
        <Step>
          <StepLabel sx={{ "& .MuiStepLabel-labelContainer": { "& .MuiStepLabel-label": { fontSize: "18px", color: "#FFFFFF" } }, "& .MuiStepIcon-text": { fill: "#FFFFFF" } }}>{t("settings")}</StepLabel>
        </Step>
      }
      {authUser.accessRoleType !== AccessRole.TYPE_ORGANIZATION &&
        <Step>
          <StepLabel sx={{ "& .MuiStepLabel-labelContainer": { "& .MuiStepLabel-label": { fontSize: "18px", color: "#FFFFFF" } }, "& .MuiStepIcon-text": { fill: "#FFFFFF" } }} >{t("payment")}</StepLabel>
        </Step>
      }
      <Step>
        <StepLabel sx={{ "& .MuiStepLabel-labelContainer": { "& .MuiStepLabel-label": { fontSize: "18px", color: "#FFFFFF" } }, "& .MuiStepIcon-text": { fill: "#FFFFFF" } }}>{authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION ? t("paymentPlans") : t("paymentConfirmation")}</StepLabel>
      </Step>
      {authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION &&
        <Step>
          <StepLabel sx={{ "& .MuiStepLabel-labelContainer": { "& .MuiStepLabel-label": { fontSize: "18px", color: "#FFFFFF" } }, "& .MuiStepIcon-text": { fill: "#FFFFFF" } }}>{t("finish")}</StepLabel>
        </Step>
      }
    </Stepper>
  )
}

export default WizardStepper;
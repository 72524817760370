import React, { useState, FocusEvent } from "react";
import { TextField, Button, Box } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Organization from "model/organization";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateOwnOrganization } from "helper/backend";
import { ValidationException } from "helper/error";
import { isErrType, showError, toSimpleError } from "helper/util";
import GridContainer from "component/common/GridContainer";
import { useTranslation } from "react-i18next";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import ProgressButton from "component/common/ProgressButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "store/actions";


type Values = {
  penaltyPercent: number | undefined;
  stripePublicKey: string;
  stripeSecretKey: string;
};

type Props = {
  organization: Organization;
};

const SectionSettingsOrganization = ({ organization }: Props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
   * These are the values loaded into the form as the component mounts
   */
  let formInitialValues: Values = {
    penaltyPercent: 0,
    stripePublicKey: '',
    stripeSecretKey: '',
  };
  formInitialValues = {
    ...formInitialValues,
    penaltyPercent: organization.penaltyPercent,
    stripePublicKey: organization.stripePublicKey || "",
    stripeSecretKey: organization.stripeSecretKey || "",
  }

  /**
  * Form validation rules
  */
  const validationSchema = {
    stripePublicKey: Yup.string().trim().required(t("fieldIsRequired")),
    stripeSecretKey: Yup.string().trim().required(t("fieldIsRequired")),
    penaltyPercent: Yup.number().required(t("fieldIsRequired"))
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, setFieldError, handleSubmit } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      saveOrganization(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveOrganization = (values: any) => {
    setIsSubmitInProgress(true)
    updateOwnOrganization(values)
      .then(_response => {
        navigate("/onboarding/payment-plans")
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            setFieldError(name, t(message));
          }
          return;
        }
        showError(t("unableSaveOrganization"));
      })
      .finally(() => {
        setIsSubmitInProgress(false)
      });
  };

  // Log out the user at quit
  const logUserOut = () => {
    dispatch(logoutUser());
  }

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box sx={{ mb: 4, textAlign: "center" }}>
        {t("onboardingSettings")}
      </Box>
      <GridContainer spacing={4}>
        <Grid xs={12}>
          <TextField type="number" name="penaltyPercent" label={t("penaltyPercent")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.penaltyPercent} error={!!errors.penaltyPercent} helperText={errors.penaltyPercent} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12}>
          <TextField name="stripePublicKey" label={t("stripePublicKey")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.stripePublicKey} error={!!errors.stripePublicKey} helperText={errors.stripePublicKey} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12}>
          <TextField name="stripeSecretKey" label={t("stripeSecretKey")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.stripeSecretKey} error={!!errors.stripeSecretKey} helperText={errors.stripeSecretKey} sx={{ width: '100%' }} />
        </Grid>
        <Grid xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" color="secondary" onClick={logUserOut} startIcon={<KeyboardArrowLeft />}>{t("quit")}</Button>
          <ProgressButton type="submit" name="submitBillingInfo" variant="contained" color="primary" isBusy={isSubmitInProgress} startIcon={<Check />}>{t("next")}</ProgressButton>
        </Grid>
      </GridContainer>
    </form >
  )
}

export default SectionSettingsOrganization;
